;(function(){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _luxon = require("luxon");

var _TextContentBlock = require("./TextContentBlock");

var _TextContentBlock2 = _interopRequireDefault(_TextContentBlock);

var _ImageContentBlock = require("./ImageContentBlock");

var _ImageContentBlock2 = _interopRequireDefault(_ImageContentBlock);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  props: {
    item: {
      type: Object,
      required: true
    },
    selectedRevision: {
      type: Object
    },
    showDiff: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TEXTContentBlock: _TextContentBlock2.default,
    IMAGEContentBlock: _ImageContentBlock2.default
  },
  computed: {
    isRevision: function isRevision() {
      return !!this.selectedRevision;
    },
    state: function state() {
      return this.selectedRevision ? this.selectedRevision.snapshot : this.item;
    },
    content_blocks: function content_blocks() {
      return this.state.template ? this.state.template.content_blocks.filter(function (block) {
        return block.type !== "VIDEO";
      }) : [];
    },
    due_date: function due_date() {
      return _luxon.DateTime.fromISO(this.state.due_date).toFormat("MMM d yyyy");
    },
    diff: function diff() {
      return this.selectedRevision.diff;
    },
    validDiff: function validDiff() {
      return this.isRevision && this.showDiff && this.diff;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"24px"}},[_c('h2',{staticClass:"title is-2"},[(_vm.validDiff && _vm.diff.updated.name_diff)?_vm._l((_vm.diff.updated.name_diff),function(ref,idx){
var mod = ref[0];
var val = ref[1];
return _c('span',{key:("diff_" + idx),class:{'-1': 'has-background-danger', '1': 'has-background-success', '0': 'has-background-grey-lighter'}[mod]},[_vm._v(_vm._s(val))])}):[_vm._v("\n      "+_vm._s(_vm.state.name)+"\n    ")]],2),_vm._v(" "),_c('div',{staticStyle:{"margin-bottom":"16px"}},[_c('strong',[_vm._v("Status:")]),_vm._v(" "+_vm._s(_vm.state.workflow_status ? _vm.state.workflow_status.name : 'Not set')+"\n    "),(_vm.validDiff && _vm.diff.updated.workflow_status_id)?[_c('b-tag',{attrs:{"type":"is-success"}},[_vm._v("Updated")])]:_vm._e()],2),_vm._v(" "),_c('div',{staticStyle:{"margin-bottom":"16px"}},[(_vm.validDiff && _vm.diff.updated.description_diff)?_vm._l((_vm.diff.updated.description_diff),function(ref,idx){
var mod = ref[0];
var val = ref[1];
return _c('span',{key:("diff_" + idx),class:{'-1': 'has-background-danger', '1': 'has-background-success', '0': 'has-background-grey-lighter'}[mod]},[_vm._v(_vm._s(val))])}):[_vm._v("\n      "+_vm._s(_vm.state.description)+"\n    ")]],2),_vm._v(" "),_c('div',{staticStyle:{"margin-bottom":"16px"}},[_c('strong',[_vm._v("Owner:")]),_vm._v(" "+_vm._s(_vm.state.owner.email)+"\n    "),(_vm.validDiff && _vm.diff.updated.owner_id)?[_c('b-tag',{attrs:{"type":"is-success"}},[_vm._v("Updated")])]:_vm._e()],2),_vm._v(" "),_c('div',{staticStyle:{"margin-bottom":"16px"}},[_c('strong',[_vm._v("Due date:")]),_vm._v(" "+_vm._s(_vm.due_date)+"\n    "),(_vm.validDiff && _vm.diff.updated.due_date)?[_c('b-tag',{attrs:{"type":"is-success"}},[_vm._v("Updated")])]:_vm._e()],2),_vm._v(" "),_c('div',{staticStyle:{"margin-bottom":"16px"}},[(_vm.state.tags.length)?_c('b-taglist',[_vm._l((_vm.state.tags),function(tag){return _c('b-tag',{key:tag.tag_id,attrs:{"type":'is-warning'}},[_vm._v(_vm._s(tag.name))])}),_vm._v(" "),(_vm.validDiff && _vm.diff.added.tag_ids)?_c('b-tag',{attrs:{"type":"is-success"}},[_vm._v("\n        + 1\n      ")]):_vm._e(),_vm._v(" "),(_vm.validDiff && _vm.diff.deleted.tag_ids)?_c('b-tag',{attrs:{"type":"is-danger"}},[_vm._v("\n        - 1\n      ")]):_vm._e()],2):_vm._e()],1),_vm._v(" "),_c('div',{staticStyle:{"margin-bottom":"16px"}},_vm._l((_vm.content_blocks),function(block){return _c(((block.type) + "ContentBlock"),{key:block.content_block_id,tag:"component",staticStyle:{"margin-bottom":"16px"},attrs:{"block":block,"parentRevisionID":_vm.selectedRevision && _vm.selectedRevision.history_record_id,"showDiff":_vm.showDiff}})}),1)])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-c9f5f11e", __vue__options__)
  } else {
    hotAPI.reload("data-v-c9f5f11e", __vue__options__)
  }
})()}