import Dashboard from '../components/Dashboard';
import ItemRevisionHistory from '../components/ItemRevisionHistory/Container';

export default [
  {
    name: 'dashboard',
    path: '/',
    component: Dashboard
  },
  {
    name: 'item-revision-history',
    path: '/item-revision',
    component: ItemRevisionHistory
  }
];
