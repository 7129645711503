;(function(){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _luxon = require("luxon");

exports.default = {
  props: {
    revisions: {
      type: Array,
      required: true
    }
  },
  data: function data() {
    return {
      isExpanded: false
    };
  },
  computed: {
    usersLabel: function usersLabel() {
      var emails = this.revisions.map(function (revision) {
        return revision.issuer;
      }).map(function (issuer) {
        return issuer.email;
      }).filter(function (val, i, a) {
        return i === a.indexOf(val);
      });

      return emails.join(", ");
    },
    changesLabel: function changesLabel() {
      return this.revisions.length + " " + (this.revisions.length === 1 ? "change" : "changes");
    },
    niceDate: function niceDate() {
      return _luxon.DateTime.fromISO(this.revisions[0].date).toFormat("EEE, MMM d yyyy");
    }
  },
  methods: {
    revisionTime: function revisionTime(ts) {
      return _luxon.DateTime.fromISO(ts).toFormat("HH:mm");
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"border-bottom":"1px solid #e2e2e2","padding":"16px"}},[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"},[_c('div',[_c('div',[_c('strong',[_vm._v("\n              "+_vm._s(_vm.niceDate)+"\n            ")])]),_vm._v(" "),_c('small',[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.isExpanded = !_vm.isExpanded}}},[_vm._v(_vm._s(_vm.changesLabel))])]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isExpanded),expression:"!isExpanded"}]},[_vm._v("by "+_vm._s(_vm.usersLabel))])])])]),_vm._v(" "),_c('div',{staticClass:"level-right"})]),_vm._v(" "),(_vm.isExpanded)?_c('section',_vm._l((_vm.revisions),function(revision,idx){return _c('div',{key:("group_rev_" + idx),staticStyle:{"border-left":"1px dashed #e2e2e2","padding":"16px"}},[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"},[_c('div',[_c('div',[_c('strong',[_vm._v("\n                  "+_vm._s(_vm.revisionTime(revision.date))+"\n                ")])]),_vm._v(" "),_c('small',{staticClass:"has-text-grey"},[_vm._v(_vm._s(revision.command))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',[_vm._v("by "+_vm._s(revision.issuer.email))])])])]),_vm._v(" "),_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"level-item"},[_c('a',{staticClass:"button is-info is-small",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('selectRevision',revision)}}},[_vm._v("Browse")])])])])])}),0):_vm._e()])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-1adb0fb1", __vue__options__)
  } else {
    hotAPI.reload("data-v-1adb0fb1", __vue__options__)
  }
})()}