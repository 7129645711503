import gql from 'graphql-tag';

export default key =>
  ({
    login: gql`
      query authentication($email: String!, $password: String!) {
        authentication(email: $email, password: $password)
      }
    `,
    me: gql`
      query me {
        me {
          first_name
          last_name
          email
        }
      }
    `,
    companyUsers: gql`
      query me {
        me {
          auth_company {
            users {
              user_id
              email
            }
          }
        }
      }
    `,
    items: gql`
      query items {
        items {
          item_id
          name
        }
      }
    `,
    item: gql`
      query item($item_id: ID!) {
        item(item_id: $item_id) {
          item_id
          name
          description
          due_date
          owner {
            email
          }
          workflow_status {
            workflow_status_id
            name
          }
          tags {
            tag_id
            name
          }
          template {
            content_blocks {
              content_block_id
              type
              value {
                ... on ImageContentBlock {
                  files {
                    upload_id
                    url
                  }
                }
                ... on TextContentBlock {
                  value
                }
              }
            }
          }
        }
      }
    `,
    itemHistory: gql`
      query item($item_id: ID!, $criteria: HistoryCriteria) {
        item(item_id: $item_id) {
          item_id
          due_date
          workflow_status {
            workflow_status_id
            name
          }
          owner {
            email
          }
          history(criteria: $criteria) {
            history_record_id
            command
            date
            diff {
              added
              updated
              deleted
            }
            snapshot {
              ... on Item {
                name
                description
                due_date
                workflow_status {
                  workflow_status_id
                  name
                }
                owner {
                  email
                }
                tags {
                  tag_id
                  name
                }
                template {
                  content_blocks {
                    content_block_id
                    type
                    value {
                      history {
                        history_record_id
                        diff {
                          added
                          updated
                          deleted
                        }
                      }
                      ... on ImageContentBlock {
                        files {
                          upload_id
                          url
                        }
                      }
                      ... on TextContentBlock {
                        value
                      }
                    }
                  }
                }
              }
            }
            issuer {
              user_id
              email
              first_name
              avatar {
                url
              }
            }
          }
        }
      }
    `
  }[key]);
