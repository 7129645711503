;(function(){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  props: {
    block: {
      type: Object,
      required: true
    },
    parentRevisionID: {
      type: String
    },
    showDiff: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  computed: {
    hasHistory: function hasHistory() {
      return this.block.value.history && this.block.value.history.length;
    },
    historyRecord: function historyRecord() {
      var _this = this;

      return this.block.value.history.filter(function (record) {
        return record.history_record_id === _this.parentRevisionID;
      })[0];
    },
    fileWasAdded: function fileWasAdded() {
      return this.hasHistory && this.historyRecord ? this.historyRecord.diff.updated.files : [];
    },
    fileWasRemoved: function fileWasRemoved() {
      return this.hasHistory && this.historyRecord ? this.historyRecord.diff.deleted.files : [];
    }
  },
  methods: {}
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"border":"1px solid #e2e2e2","padding":"8px"}},[_vm._m(0),_vm._v(" "),_c('div',[_vm._l((_vm.block.value.files),function(file){return _c('img',{key:file.upload_id,staticStyle:{"width":"80px","height":"80px"},attrs:{"src":file.url}})}),_vm._v(" "),_c('b-taglist',[(_vm.showDiff && _vm.fileWasAdded)?_c('b-tag',{attrs:{"type":"is-success"}},[_vm._v("\n        + 1\n      ")]):_vm._e(),_vm._v(" "),(_vm.showDiff && _vm.fileWasRemoved)?_c('b-tag',{attrs:{"type":"is-danger"}},[_vm._v("\n        - 1\n      ")]):_vm._e()],1)],2)])}
__vue__options__.staticRenderFns = [function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('small',[_vm._v("\n      [image-content-block]\n    ")])])}]
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-18254c46", __vue__options__)
  } else {
    hotAPI.reload("data-v-18254c46", __vue__options__)
  }
})()}