import Vue from 'vue';
import Buefy from 'buefy';
import VueRouter from 'vue-router';

import * as ____ from 'core-js';

import App from './App.vue';
import routes from './routes';
import { apollo, store } from './providers';

Vue.use(Buefy);
Vue.use(VueRouter);

Vue.use({
  install: Vue => {
    Vue.prototype.$store = store;
    Vue.prototype.$apollo = apollo;
  }
});

new Vue({
  el: '#app',
  render: h => h(App),
  router: new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior: (to, from, savedPosition) => ({ x: 0, y: 0 })
  })
});
