import Vue from 'vue';

const PERSISTENCE_KEY = 'cp_proto_store';

const default_state = {
  auth_token: '',
  first_name: '',
  last_name: ''
};

const state = Vue.observable({ ...default_state });

export const persistence = {
  has: () => Boolean(localStorage.getItem(PERSISTENCE_KEY)),
  get: () => JSON.parse(localStorage.getItem(PERSISTENCE_KEY)),
  set: update => localStorage.setItem(PERSISTENCE_KEY, JSON.stringify(update)),
  clear: () => localStorage.removeItem(PERSISTENCE_KEY)
};

export const store = {
  authToken: () => state.auth_token,
  isAuth: () => Boolean(state.auth_token),
  update: update => {
    Object.keys(update).forEach(key => (state[key] = update[key]));
    persistence.set(state);
  },
  clear: () => {
    Object.keys(state).forEach(key => (state[key] = default_state[key]));
    persistence.clear();
  }
};

persistence.has() && store.update(persistence.get());
