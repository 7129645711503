
require.register("core-js/modules/core.function.part.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "core-js");
  (function() {
    var path = require('./_path');
var $export = require('./_export');

// Placeholder
require('./_core')._ = path._ = path._ || {};

$export($export.P + $export.F, 'Function', { part: require('./_partial') });
  })();
});