import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';

import schemas from './schemas';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: ['ImageContentBlock', 'TextContentBlock']
    }
  }
});

const authLink = token =>
  setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`
      }
    };
  });

const httpLink = createHttpLink({
  uri: 'https://api.staging.contentpaul.io/api'
});

const client = token =>
  new ApolloClient({
    cache: new InMemoryCache({ fragmentMatcher }),
    link: authLink(token).concat(httpLink)
  });

export default {
  client,
  schemas
};
