;(function(){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  props: {
    block: {
      type: Object,
      required: true
    },
    parentRevisionID: {
      type: String
    },
    showDiff: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    hasHistory: function hasHistory() {
      return this.block.value.history && this.block.value.history.length;
    },
    historyRecord: function historyRecord() {
      var _this = this;

      return this.block.value.history.filter(function (record) {
        return record.history_record_id === _this.parentRevisionID;
      })[0];
    },
    valueDiff: function valueDiff() {
      return this.hasHistory && this.historyRecord ? this.historyRecord.diff.updated.value_diff : [];
    }
  },
  methods: {}
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"border":"1px solid #e2e2e2","padding":"8px"}},[_vm._m(0),_vm._v(" "),(_vm.showDiff && _vm.hasHistory && _vm.historyRecord)?_c('div',_vm._l((_vm.valueDiff),function(ref,idx){
var mod = ref[0];
var val = ref[1];
return _c('span',{key:("diff_" + idx),class:{'-1': 'has-background-danger', '1': 'has-background-success', '0': 'has-background-grey-lighter'}[mod]},[_vm._v(_vm._s(val))])}),0):_c('div',[_vm._v("\n    "+_vm._s(_vm.block.value.value)+"\n  ")])])}
__vue__options__.staticRenderFns = [function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('small',[_vm._v("\n      [text-content-block]\n    ")])])}]
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-f734ba0c", __vue__options__)
  } else {
    hotAPI.reload("data-v-f734ba0c", __vue__options__)
  }
})()}